import { Injectable } from '@angular/core';
import { DataSourceDomain } from '../../../editor/ui/data-sources/model/data-source.model';
import { CommandService, Logger, Random } from 'flux-core';
import { DataType, EntityLinkType, IDataItem, IEntityDef, IValidationError } from 'flux-definition/src';
import { DataItemFactory } from 'flux-diagram-composer';
import { chunk } from 'lodash';
import { concat, defer, of, throwError } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { catchError, last, map, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { CsvUtil } from '../../diagram/export/csv-util';
import { EDataCommandEvent } from '../command/edata-command-event';
import { EDataRegistry } from '../edata-registry.svc';
import { EDataModel, IDataSourceGoogleSheet } from '../model/edata.mdl';
import { EntityModel } from '../model/entity.mdl';
import { CSVImportValidationError, ICsvMappingError } from './error/csv-import-validation-error';
import { Proxied, Sakota } from '@creately/sakota';
import { PeopleLocator } from '../../ui/shape-data-editor/people-locator';
import { difference } from 'lodash';
import { mapUserEntries } from './shared';
import { NangoService } from '../../../editor/ui/data-sources/nango-service';

@Injectable()
export class GoogleSheetDataImporter {

    protected batchSize = 1000;

    constructor(
        private commandSvc: CommandService,
        private nangoSvc: NangoService,
        private pl: PeopleLocator,
    ) {}

    public refreshData( model: EDataModel, proceedWithErrors = false ) {
        if ( !model.dataSource || model.dataSource.domain !== DataSourceDomain.GoogleSheets ) {
            throw new Error( 'Invalid data source' );
        }
        const { id: spreadsheetId } = model.dataSource as IDataSourceGoogleSheet;
        // order of types to update ???? -> same as imported order
        const obs = Object.keys( model.dataSourceMappings ).map( eDefId => {
            const { updatedBy, ...config } = model.dataSourceMappings[eDefId];
            return defer(() => this.importData( model, {
                ...config,
                eDefId,
                spreadsheetId,
            })).pipe(
                proceedWithErrors ? catchError( e => {
                    Logger.error( 'Error refreshing data', e );
                    return of({
                        entities: [],
                        errors: [ e ],
                    });
                }) : tap({
                    error: e => {
                        Logger.error( 'Error refreshing data', e );
                    },
                }),
            );
        });
        return concat( ...obs );
    }

    public importData( model: EDataModel, config: any ) {
        const { eDefId, ignoreMappingErrors, mappings } = config;
        const ctx: any = {
            eDataId: model.id,
            eDataDefId: model.defId,
            mappingExists: false,
            existingEntities: model.entities,
        };
        if ( model.isCustom && !model.customEntityDefs.hasOwnProperty( eDefId )) {
            const errMessage = `Custom entity definition not found. defId: '${eDefId}'`;
            return throwError( new CSVImportValidationError( errMessage ));
        }
        if ( model.dataSource ) {
            if ( model.dataSource.domain !== DataSourceDomain.GoogleSheets
                || ( model.dataSource as any ).id !== config.spreadsheetId ) {
                const errMessage = `Invalid data source. domain: '${model.dataSource.domain}'`;
                return throwError( new CSVImportValidationError( errMessage ));
            }
            if ( model.dataSourceMappings[eDefId]) {
                const { sheet, rowIdentifierField } = model.dataSourceMappings[eDefId];
                if ( sheet.sheetId !== config.sheet.sheetId || rowIdentifierField !== config.rowIdentifierField ) {
                    const errMessage = 'mapping already exists and differs from current mappings';
                    return throwError( new CSVImportValidationError( errMessage ));
                }
                ctx.mappingExists = true;
            } else {
                for ( const entityDefId in model.dataSourceMappings ) {
                    if ( model.dataSourceMappings[entityDefId].sheet.sheetId === config.sheet.sheetId ) {
                        const message = 'sheet is already mapped to a different object type';
                        throwError( new CSVImportValidationError( message ));
                    }
                }
            }
        }
        const idMap = {};
        Object.values( model.entities ).filter( e => e.dataSource ).forEach( e => {
            if ( !idMap[e.eDefId]) {
                idMap[e.eDefId] = {};
            }
            idMap[e.eDefId][e.dataSource.data.dataSourceId] = e.id;
        });
        ctx.idMap = idMap;
        const customEDef = model.isCustom ? model.customEntityDefs[eDefId] :
            EDataRegistry.instance.getEntityDefById( eDefId, model.defId );
        const hasUsers = mappings.map( m => customEDef.dataItems[m.dataItemId])
            .some( di => di.type === DataType.USERS );
        const contextObs = hasUsers ? this.pl.getAllPeople( '*' ).pipe(
            take( 1 ),
            map( sources => ({
                ...ctx,
                peopleSources: sources,
            })),
        ) : of( ctx );
        return contextObs.pipe(
            switchMap( context => fromPromise( this.getEntities( customEDef, config, context, model ))),
            switchMap(({ entities, errors }) => {
                if ( !ignoreMappingErrors && errors.length > 0 ) { // abort on errors
                    Logger.warning( 'aborting the import. validation errors: ', errors );
                    return throwError( new CSVImportValidationError( 'mappings error', errors ));
                } else if ( entities.length === 0 ) {
                    Logger.warning( 'aborting the import. no data to import' );
                    return throwError( new CSVImportValidationError( 'no data to import', errors ));
                }
                Logger.info( 'Importing entities...' );
                const observables = chunk( entities, this.batchSize )
                    .map( batch => defer(() => this.commandSvc.dispatch( EDataCommandEvent.importEntities, model.id, {
                        entities: batch,
                    })));
                return concat( ...observables ).pipe(
                    last(),
                    tap(() => Logger.info( 'Finished importing entities' )),
                    mapTo({ entities, errors }),
                );
            }),
        );
    }

    protected async getRows( startRow: number, endRow: number, spreadsheetId: string, sheetName: string ) {
        return this.nangoSvc.fetchPost({
            dataSource: DataSourceDomain.GoogleSheets,
            method: 'listRows',
            params: {
                startRow,
                endRow,
                spreadsheetId,
                sheetName,
            },
        });
    }

    protected async getEntities( customEDef: IEntityDef, config: any, context: any, model: EDataModel ) {
        const { mappings, eDefId, ignoreMappingErrors } = config;
        if ( !context.idMap[eDefId]) {
            context.idMap[eDefId] = {};
        }
        const idMap = context.idMap[eDefId];
        const mapper = {};
        const userData = {};
        if ( context.peopleSources ) {
            const collabs = context.peopleSources.find( s => s.source.id === 'collabs' ).people;
            collabs.forEach( c => userData[c.email] = c );
        }
        const baseCharCode = 'A'.charCodeAt( 0 );
        for ( const mapping of mappings ) {
            const { columnIndex, dataItemId } = mapping;
            const charCode = columnIndex.charCodeAt( 0 ) - baseCharCode;
            if ( charCode < 0 || charCode > 25 ) {
                throw new Error( `invalid column. column index: ${columnIndex}` );
            }
            if ( !customEDef.dataItems[dataItemId]) {
                throw new Error( `Data item with id '${dataItemId}' not found` );
            }
            const { type: dataType, options } = customEDef.dataItems[dataItemId] as any;
            if ( dataType === DataType.USERS ) {
                mapper[dataItemId] = {
                    columnIndex,
                    parse: str => {
                        if ( !str ) {
                            return {
                                source: {
                                    id: 'collabs',
                                    name: 'Collaborators',
                                },
                                people: [],
                            };
                        }
                        const entries = str.split( ',' ).map( s => s.trim());
                        return mapUserEntries( entries, userData, model );
                    },
                    validator: {
                        validate: val => null,
                        // validate: val => {
                        //     // // Since we allow non-existing users, No need to validate userId
                        //     //
                        //     // if ( val.people.some( p => !p.id )) {
                        //     //     const email = val.people.find( p => !p.id ).email;
                        //     //     return {
                        //     //         message: `invalid email: '${email}'`,
                        //     //     };
                        //     // }
                        //     return null;
                        // },
                    },
                };
            } else if ( dataType === DataType.LOOKUP ) {
                if ( options.eDefId !== eDefId && !context.idMap[options.eDefId]) {
                    return { entities: [], errors: [{
                        rowIndex: 0,
                        columnIndex,
                        parsedValue: null,
                        value: '',
                        error: {
                            message: 'Referenced object mappings not found. please import it first.',
                        },
                    }]};
                }
                mapper[dataItemId] = {
                    columnIndex,
                    resolver: ( entity, str ) => ( ctx => {
                        let val = str && str.length > 0 ? str.split( ',' )
                            .map( s => s.trim())
                            .filter( s => s.length > 0 ).map( id => ctx.idMap[options.eDefId][id]) : [];
                        if ( ignoreMappingErrors ) {
                            val = val.filter( eId => !!eId );
                        } else {
                            if ( val.some( eId => !eId )) {
                                throw new Error( 'Invalid reference value' );
                            }
                        }
                        entity.data[dataItemId] = val;
                        if ( ctx.existingEntities[entity.id]) {
                            if ( !entity.data.__sakota__.hasChanges( dataItemId )) {
                                return;
                            }
                            const currVal = ctx.existingEntities[entity.id].data[dataItemId];
                            if ( !currVal || currVal.length === 0 ) {
                                this.addReferences( entity, dataItemId, val, ctx );
                            } else if ( val.length === 1 && currVal.length === 1 ) {
                                this.removeReferences( entity, dataItemId, currVal, ctx );
                                this.addReferences( entity, dataItemId, val, ctx );
                            } else {
                                const toBeRemoved = difference( currVal, val );
                                const toBeAdded = difference( val, currVal );
                                this.removeReferences( entity, dataItemId, toBeRemoved, ctx );
                                this.addReferences( entity, dataItemId, toBeAdded, ctx );
                            }
                        } else {
                            this.addReferences( entity, dataItemId, val, ctx );
                        }
                    }),
                    validator: {
                        validate: () => null,
                    },
                };
            } else {
                mapper[dataItemId] = {
                    columnIndex,
                    parse: CsvUtil.getParser( customEDef.dataItems[dataItemId].type ),
                    validator: DataItemFactory.instance.create({
                        ...customEDef.dataItems[dataItemId],
                        validationRules: dataType === DataType.NUMBER ? {
                            decimal: true,
                        } : {},
                        typeParams: dataType === DataType.OPTION_LIST ? { options } : {},
                    }),
                };
            }
        }
        const entities: EntityModel[] = [];
        const errors: ICsvMappingError[] = [];
        const toBeResolved = [];
        let i = 2;
        let rows = [];
        let batch = [];
        do {
            batch = await this.getRows( i, i + 999, config.spreadsheetId, config.sheet.title );
            batch.forEach(( row, idx ) => row.RowNumber = i + idx );
            rows = rows.concat( batch );
            i = i + 1000;
        } while ( batch.length === 1000 );
        for ( let index = 0; index < rows.length; index++ ) {
            const row = rows[index];
            const idValue = row[config.rowIdentifierField];
            if ( idValue === '' ) {
                Logger.warning( 'skipping row with empty id value', row );
                continue;
            }
            let entity: EntityModel | Proxied<EntityModel>;
            let isExistingEntity = false;
            if ( idMap[ idValue ]) {
                const entityId = idMap[idValue];
                if ( !context.existingEntities[entityId]) {
                    // ignoring duplicate data row.
                    Logger.warning( 'duplicate data row detected ', row, entityId );
                    continue;
                }
                isExistingEntity = true;
                entity = Sakota.create( context.existingEntities[entityId]);
            } else {
                entity = new EntityModel( Random.entityId(), eDefId );
                entity.defId = context.eDataDefId || EDataRegistry.customEdataDefId;
                entity.data = {};
                entity.shapes = {};
                entity.dataSource = {
                    domain: DataSourceDomain.GoogleSheets,
                    data: {
                        dataSourceId: idValue,
                        rowNumber: row.RowNumber,
                    },
                };
                if ( context.eDataDefId === EDataRegistry.customEdataDefId ) {
                    entity.style = {
                        shape: { ...customEDef.defaultShape.style.shape },
                        bounds: { ...customEDef.defaultShape.style.bounds,
                            defaultBounds: { ...customEDef.defaultShape.style.bounds.defaultBounds },
                        },
                    };
                }
                // setting default data
                for ( const dId in customEDef.dataItems ) {
                    const dataDef = customEDef.dataItems[dId];
                    if ( dataDef.type === DataType.LOOKUP ) {
                        entity.data[dId] = [];
                    } else {
                        entity.data[dId] = dataDef.default;
                    }
                }
            }
            let error: IValidationError = null;
            let allErrored = true;
            for ( const dataItemId in mapper ) {
                if ( mapper[dataItemId].resolver ) {
                    const val = row[mapper[dataItemId].columnIndex];
                    toBeResolved.push([
                        mapper[dataItemId].resolver( entity, val ),
                        { rowIndex: index, columnIndex: mapper[dataItemId].csvIndex, value: val },
                    ]);
                } else if ( mapper[dataItemId].parse ) {
                    entity.data[dataItemId] = mapper[dataItemId].parse( row[mapper[dataItemId].columnIndex]);
                } else {
                    entity.data[dataItemId] = row[mapper[dataItemId].columnIndex];
                }
                const validator: IDataItem<DataType> = mapper[dataItemId].validator;
                if ( error = validator.validate( entity.data[dataItemId])) { // if validation errors
                    errors.push({
                        rowIndex: index,
                        columnIndex: mapper[dataItemId].columnIndex,
                        parsedValue: entity.data[dataItemId],
                        value: row[mapper[dataItemId].columnIndex],
                        error,
                    });
                    if ( ignoreMappingErrors ) {
                        if ( isExistingEntity ) {
                            entity.data.__sakota__.reset( dataItemId );
                        } else {
                            delete entity.data[dataItemId];
                        }
                    } else {
                        // returning as no further processing is required.
                        return { entities, errors };
                    }
                } else {
                    allErrored = false;
                }
            }
            if ( !allErrored ) { // if at least one mapping is correct
                idMap[entity.dataSource.data.dataSourceId] = entity.id;
                entities.push( entity );
            }
        }
        const entById = {};
        entities.forEach( e => entById[e.id] = e );
        context.entities = entById;
        context.allEntities = { ...context.existingEntities, ...entById };
        toBeResolved.forEach(([ fn, ctx ]) => {
            try {
                fn( context );
            } catch ( e ) {
                errors.push({
                    rowIndex: ctx.rowIndex,
                    columnIndex: ctx.columnIndex,
                    parsedValue: null,
                    value: ctx.value,
                    error: {
                        message: e.message,
                    },
                });
            }
        });
        // after resolving relations there can be more entities added to the context.
        return { entities: Object.values( entById ), errors };
    }

    private getEntity( entityId, ctx ) {
        if ( !ctx.entities[ entityId ]) {
            if ( ctx.existingEntities[ entityId ]) {
                ctx.entities[ entityId ] = Sakota.create( ctx.existingEntities[ entityId ]);
            } else {
                Logger.error( 'entity not found. id: ', entityId );
                throw new Error( 'Invalid entity id' );
            }
        }
        return ctx.entities[ entityId ];
    }

    private removeReferences( entity, dataItemId, val, ctx ) {
        const reversedId = dataItemId.split( '' ).reverse().join( '' );
        val.forEach( id => {
            entity.removeLink(
                entity.getLinkId(
                    EntityLinkType.LOOKUP,
                    dataItemId, ctx.eDataId, id,
                ),
            );
            const connectedEntity = this.getEntity( id, ctx );
            const mirrorFieldId = connectedEntity.eDefId === entity.eDefId ? reversedId : dataItemId;
            connectedEntity.data[mirrorFieldId] =
                connectedEntity.data[mirrorFieldId].filter( eid => eid !== entity.id );
            connectedEntity.removeLink(
                connectedEntity.getLinkId(
                    EntityLinkType.LOOKUP,
                    mirrorFieldId, ctx.eDataId, entity.id,
                ),
            );
        });
    }

    private addReferences( entity, dataItemId, val, ctx ) {
        const reversedId = dataItemId.split( '' ).reverse().join( '' );
        val.forEach( id => {
            entity.addLink({
                id: Random.linkId(),
                eDataId: ctx.eDataId,
                entityId: id,
                type: EntityLinkType.LOOKUP,
                handshake: dataItemId,
                connectors: {},
            });
            const connectedEntity = this.getEntity( id, ctx );
            const mirrorFieldId = connectedEntity.eDefId === entity.eDefId ? reversedId : dataItemId;
            if ( !connectedEntity.data[mirrorFieldId]) {
                connectedEntity.data[mirrorFieldId] = [];
            }
            connectedEntity.data[mirrorFieldId] =
                connectedEntity.data[mirrorFieldId].concat([ entity.id ]);
            connectedEntity.addLink({
                id: Random.linkId(),
                eDataId: ctx.eDataId,
                entityId: entity.id,
                type: EntityLinkType.LOOKUP,
                handshake: mirrorFieldId,
                connectors: {},
            });
        });
    }
}
