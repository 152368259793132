import { DataType, DEFUALT_TEXT_STYLES, TEXT_PADDING_HORIZONTAL } from 'flux-definition';
import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { sortBy } from 'lodash';
import { ShapeModel } from 'apps/nucleus/src/base/shape/model/shape.mdl';
import * as Carota from '@creately/carota';

@Injectable()
@Command()
export class ModifyTable extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
    public data: {
        tableId: string,
        columns: [],
        rows: [],
        columnSizes: number[],
        rowSizes: number[],
        bounds: { x: number, y: number },
        // cellStyles: any,
        cells?: any,
        mergedCells?: Array<Array<string>>;
        defaultCell?: any;
        zIndex?: number;
    };

    // tslint:disable-next-line:cyclomatic-complexity
    public prepareData() {
        this.resultData = { ...this.data };

        const id = this.data.tableId;
        const value = this.data;
        if ( !this.data.cells ) {
            this.data.cells = {};
        }
        const isGenericTable = ( !!!this.data.columns || this.data.columns.length === 0 )
            && ( !!!this.data.rows || this.data.rows.length === 0 )
            && !!this.data.columnSizes
            && !!this.data.rowSizes;
        let columns = this.data.columns || [];
        let rows = this.data.rows || [];
        const defaultCell =  {
            text: '',
            fillColor: 'white',
            textColor: 'black',
            textSize: 10,
            textAlignX: -1, // -1, 0, 1
            textAlignY: -1, // -1, 0, 1
            lineThickness: 2,
            lineColor: 'black',
            lineStyle: [ 0, 0 ],
            layoutingData: { algorithm: 'free', source: 'native' },
        };
        this.data.defaultCell = this.data.defaultCell ?
        { ...defaultCell, ...this.data.defaultCell } : defaultCell;

        if ( isGenericTable ) {
            columns = this.data.columnSizes.map(( size, i ) => '' ) as any;
            rows = this.data.rowSizes.map(( size, i ) => '' ) as any;
            rows.forEach(( val, r )  => {
                columns.forEach(( v, c ) => {
                    const cell = this.data.cells[ `${ r + 1 }-${ c + 1 }` ];
                    if ( !cell ) {
                        this.data.cells[ `${ r + 1 }-${ c + 1 }` ] = { ...this.data.defaultCell };
                    }
                });
            });
        }

        let template = '';
        if ( isGenericTable ) {
            template = 'generic';
        } else if ( columns.length > 0  && rows.length === 0 ) {
            template = 'columns';
        } else if ( columns.length === 0  && rows.length > 0 ) {
            template = 'rows';
        } else if ( columns.length > 0  && rows.length > 0 ) {
            template = 'columns-rows';
        }

        const table = this.changeModel.shapes[id] as ShapeModel;

        if ( value.zIndex !== undefined ) {
            table.zIndex = value.zIndex;
        }

        table.x = value.bounds.x;
        table.y = value.bounds.y;

        const layoutReg: any = Object.values( table.containerRegions )
            .find(( region:  any )  => region.layoutingId );

        const layoutingId = layoutReg?.layoutingId || 'elk-rectpacking';
        if ( template === 'columns' ) {
            // map column name to region id
            const cellsMap = {};

            // Prepare table columns
            const _cells = Object.values( table.data )
                .filter(( data:  any ) =>
                    data.type === DataType.CHILD_SHAPE )
                .map(( data:  any )  => data.value );
            const sortedByCols = sortBy( _cells, cell => cell.columnId ).reverse();
            const noOfCols = sortedByCols[ 0 ].columnId;
            const requiredCols = columns.length - noOfCols;
            if ( requiredCols > 0 ) {
                for ( let i = 0; i < requiredCols; i++ ) {
                    ( table as any ).addColumnRight( table, this.changeModel );
                }
            } else if ( requiredCols < 0 ) {
                for ( let i = 0; i < Math.abs( requiredCols ); i++ ) {
                    ( table as any ).removeColumnRight( table, this.changeModel );
                }
            }

            // Update columns name and cellsMap
            const cells = Object.values( table.data )
                .filter(( data:  any ) =>
                    data.type === DataType.CHILD_SHAPE )
                .map(( data:  any )  => data.value );
            columns.forEach(( colText: any, i ) => {
                const sortedByRows = sortBy( cells, cell => cell.rowId );
                const topmostRowId = sortedByRows[0].rowId;
                const topRowCells = sortedByRows.filter( cell => cell.rowId === topmostRowId );
                const _secondRowCells = sortedByRows.filter( cell => cell.rowId === topmostRowId + 1 );
                const secondRowCells = sortBy( _secondRowCells, cell => cell.columnId );
                const headCell = topRowCells.find( cell => cell.columnId === i + 1 );
                cellsMap[ colText ] = secondRowCells[i];
                const styleRefShape = this.changeModel.getLayoutingShapesInRegion(
                    table.id, secondRowCells[i].id,
                )?.[0];
                cellsMap[ colText ].styleRefShape = styleRefShape;
                const region = table.containerRegions[ secondRowCells[i].id ];
                ( region as any ).layoutingId  = layoutingId;
                if ( colText ) {
                    table.texts[ headCell.id ].content = [{
                        ...table.texts[ headCell.id ].content[0],
                        text: colText,
                    }];
                }
                if ( this.data.columnSizes ) {
                    const columnSize = this.data.columnSizes[ i ];
                    if ( columnSize !== undefined ) {
                        ( table as any ).autoResizeCellAbs( 'x', this.changeModel, table,
                            headCell.id, columnSize, false );
                    }
                }
            });
            this.resultData.cellsMapVertical = cellsMap;
        }

        if ( template === 'rows' ) {
            const cellsMap = {};

            // Prepare table rows
            const _cells = Object.values( table.data )
                .filter(( data:  any ) =>
                    data.type === DataType.CHILD_SHAPE )
                .map(( data:  any )  => data.value );
            const sortedByRows = sortBy( _cells, cell => cell.rowId ).reverse();
            const noOfRows = sortedByRows[ 0 ].rowId;
            const requiredRows = rows.length - noOfRows;
            if ( requiredRows > 0 ) {
                for ( let i = 0; i < requiredRows; i++ ) {
                    ( table as any ).addRowBelow( table, this.changeModel );
                }
            } else if ( requiredRows < 0 ) {
                for ( let i = 0; i < Math.abs( requiredRows ); i++ ) {
                    ( table as any ).removeRowBelow( table, this.changeModel );
                }
            }

            const cells = Object.values( table.data )
                .filter(( data:  any ) =>
                    data.type === DataType.CHILD_SHAPE )
                .map(( data:  any )  => data.value );
            rows.forEach(( rowText: any, i ) => {
                const sortedByCols = sortBy( cells, cell => cell.columnId );
                const leftmostColId = sortedByCols[0].columnId;
                const leftColCells = sortedByCols.filter( cell => cell.columnId === leftmostColId );
                const _secondColCells = sortedByCols.filter( cell => cell.columnId === leftmostColId + 1 );
                const secondColCells = sortBy( _secondColCells, cell => cell.rowId );
                const headCell = leftColCells.find( cell => cell.rowId === i + 1 );
                cellsMap[ rowText ] = secondColCells[i];
                const styleRefShape = this.changeModel.getLayoutingShapesInRegion(
                    table.id,
                    secondColCells[i].id,
                )?.[0];
                cellsMap[ rowText ].styleRefShape = styleRefShape;
                const region = table.containerRegions[ secondColCells[i].id ];
                ( region as any ).layoutingId  = layoutingId;
                if ( rowText ) {
                    table.texts[ headCell.id ].content = [{
                        ...table.texts[ headCell.id ].content[0],
                        text: rowText,
                    }];
                }
                if ( this.data.rowSizes ) {
                    const rowSize = this.data.rowSizes[ i ];
                    if ( rowSize !== undefined ) {
                        ( table as any ).autoResizeCellAbs( 'y', this.changeModel, table,
                            headCell.id, rowSize, false );
                    }
                }
            });
            this.resultData.cellsMapHorizontal = cellsMap;
        }

        if ( template === 'columns-rows' ) {

            // Prepare table columns
            const _cells = Object.values( table.data )
                .filter(( data:  any ) =>
                    data.type === DataType.CHILD_SHAPE )
                .map(( data:  any )  => data.value );
            const sortedByCols = sortBy( _cells, cell => cell.columnId ).reverse();
            const noOfCols = sortedByCols[ 0 ].columnId - 1;
            const requiredCols = columns.length - noOfCols;
            if ( requiredCols > 0 ) {
                for ( let i = 0; i < requiredCols; i++ ) {
                    ( table as any ).addColumnRight( table, this.changeModel );
                }
            } else if ( requiredCols < 0 ) {
                for ( let i = 0; i < Math.abs( requiredCols ); i++ ) {
                    ( table as any ).removeColumnRight( table, this.changeModel );
                }
            }

            // Prepare table rows
            const sortedByRows = sortBy( _cells, cell => cell.rowId ).reverse();
            const noOfRows = sortedByRows[ 0 ].rowId - 1;
            const requiredRows = rows.length - noOfRows;
            if ( requiredRows > 0 ) {
                for ( let i = 0; i < requiredRows; i++ ) {
                    ( table as any ).addRowBelow( table, this.changeModel );
                }
            } else if ( requiredRows < 0 ) {
                for ( let i = 0; i < Math.abs( requiredRows ); i++ ) {
                    ( table as any ).removeRowBelow( table, this.changeModel );
                }
            }

            const cells = Object.values( table.data )
                .filter(( data:  any ) =>
                    data.type === DataType.CHILD_SHAPE )
                .map(( data:  any )  => data.value );
            columns.forEach(( colText: any, i ) => {
                const sortedRows = sortBy( cells, cell => cell.rowId );
                const topmostRowId = sortedRows[0].rowId;
                const topRowCells = sortedRows.filter( cell => cell.rowId === topmostRowId );
                const headCell = topRowCells.find( cell => cell.columnId === i + 2 );
                if ( colText ) {
                    table.texts[ headCell.id ].content = [{
                        ...table.texts[ headCell.id ].content[0],
                        text: colText,
                    }];
                }
                if ( this.data.columnSizes ) {
                    const columnSize = this.data.columnSizes[ i ];
                    if ( columnSize !== undefined ) {
                        ( table as any ).autoResizeCellAbs( 'x', this.changeModel, table,
                            headCell.id, columnSize, false );
                    }
                }
            });

            rows.forEach(( rowText: any, i ) => {
                const sortedCols = sortBy( cells, cell => cell.columnId );
                const leftmostColId = sortedCols[0].columnId;
                const leftColCells = sortedCols.filter( cell => cell.columnId === leftmostColId );
                const headCell = leftColCells.find( cell => cell.rowId === i + 2 );
                if ( rowText ) {
                    table.texts[ headCell.id ].content = [{
                        ...table.texts[ headCell.id ].content[0],
                        text: rowText,
                    }];
                }
                if ( this.data.rowSizes ) {
                    const rowSize = this.data.rowSizes[ i ];
                    if ( rowSize !== undefined ) {
                        ( table as any ).autoResizeCellAbs( 'y', this.changeModel, table,
                            headCell.id, rowSize, false );
                    }
                }
            });

            const cellsMapVerticalHorizontal = {};
            columns.forEach(( colText: any, i ) => {
                const columnId = i + 2;
                cellsMapVerticalHorizontal[ colText ] = {};
                rows.forEach(( rowText: any, j ) => {
                    const rowId = j + 2;
                    const cell = cells.find( c => c.columnId === columnId && c.rowId === rowId );
                    const region = table.containerRegions[ cell.id ];
                    ( region as any ).layoutingId  = layoutingId;
                    cellsMapVerticalHorizontal[ colText ][ rowText ] = cell;
                    const styleRefShape = this.changeModel.getLayoutingShapesInRegion( table.id, cell.id )?.[0];
                    cellsMapVerticalHorizontal[ colText ][ rowText ].styleRefShape = styleRefShape;
                });
            });
            this.resultData.cellsMapVerticalHorizontal = cellsMapVerticalHorizontal;
        }

        if ( template === 'generic' ) {
            // Prepare table columns
            const _cells = Object.values( table.data )
                .filter(( data:  any ) =>
                    data.type === DataType.CHILD_SHAPE )
                .map(( data:  any )  => data.value );
            const sortedByCols = sortBy( _cells, cell => cell.columnId ).reverse();
            const noOfCols = sortedByCols[ 0 ].columnId;
            const requiredCols = columns.length - noOfCols;
            if ( requiredCols > 0 ) {
                for ( let i = 0; i < requiredCols; i++ ) {
                    ( table as any ).addColumnRight( table, this.changeModel );
                }
            } else if ( requiredCols < 0 ) {
                for ( let i = 0; i < Math.abs( requiredCols ); i++ ) {
                    ( table as any ).removeColumnRight( table, this.changeModel );
                }
            }

            // Prepare table rows
            const sortedByRows = sortBy( _cells, cell => cell.rowId ).reverse();
            const noOfRows = sortedByRows[ 0 ].rowId;
            const requiredRows = rows.length - noOfRows;
            if ( requiredRows > 0 ) {
                for ( let i = 0; i < requiredRows; i++ ) {
                    ( table as any ).addRowBelow( table, this.changeModel );
                }
            } else if ( requiredRows < 0 ) {
                for ( let i = 0; i < Math.abs( requiredRows ); i++ ) {
                    ( table as any ).removeRowBelow( table, this.changeModel );
                }
            }


            const cells = Object.values( table.data )
                .filter(( data:  any ) =>
                    data.type === DataType.CHILD_SHAPE )
                .map(( data:  any )  => data.value );

            this.mergeCells( table, cells );

            columns.forEach(( colText: any, i ) => {
                const sortedRows = sortBy( cells, cell => cell.rowId );
                const topmostRowId = sortedRows[0].rowId;
                const topRowCells = sortedRows.filter( cell => cell.rowId === topmostRowId );
                const headCell = topRowCells.find( cell => cell.columnId === i + 1 );
                if ( this.data.columnSizes ) {
                    const columnSize = this.data.columnSizes[ i ];
                    if ( columnSize !== undefined ) {
                        ( table as any ).autoResizeCellAbs( 'x', this.changeModel, table,
                            headCell.id, columnSize, false );
                        // $$ignoreRowVectorAxisChange is used to avoid incrementing the row vector
                        // unnecessaryly in the table logic ( Shapecode binder ) for vector points changes
                        ( this.changeModel as any ).$$ignoreColVectorAxisChange = true;
                    }
                }
            });

            rows.forEach(( rowText: any, i ) => {
                const sortedCols = sortBy( cells, cell => cell.columnId );
                const leftmostColId = sortedCols[0].columnId;
                const leftColCells = sortedCols.filter( cell => cell.columnId === leftmostColId );
                const headCell = leftColCells.find( cell => cell.rowId === i + 1 );
                if ( this.data.rowSizes ) {
                    const rowSize = this.data.rowSizes[ i ];
                    if ( rowSize !== undefined ) {
                        ( table as any ).autoResizeCellAbs( 'y', this.changeModel, table,
                            headCell.id, rowSize, false );
                        // $$ignoreRowVectorAxisChange is used to avoid incrementing the row vector
                        // unnecessaryly in the table logic ( Shapecode binder ) for vector points changes
                        ( this.changeModel as any ).$$ignoreRowVectorAxisChange = true;
                    }
                }
            });

            const cellsMapVerticalHorizontal = {};
            columns.forEach(( colText: any, i ) => {
                const columnId = i + 1;
                cellsMapVerticalHorizontal[ colText ] = {};
                rows.forEach(( rowText: any, j ) => {
                    const rowId = j + 1;
                    const cell = cells.find( c => c.columnId === columnId && c.rowId === rowId );
                    const region = table.containerRegions[ cell.id ];

                    const cellData = this.data.cells[ `${ j + 1 }-${ i + 1 }` ];
                    if ( cellData.layoutingId ) {
                        ( region as any ).layoutingId  = layoutingId;
                    } else {
                        region.layoutingData = { algorithm: 'free', source: 'native' } as any;
                        delete ( region as any ).layoutingId;
                    }
                    cellsMapVerticalHorizontal[ colText ][ rowText ] = cell;
                    const styleRefShape = this.changeModel.getLayoutingShapesInRegion( table.id, cell.id )?.[0];
                    cellsMapVerticalHorizontal[ colText ][ rowText ].styleRefShape = styleRefShape;
                });
            });
            this.resultData.cellsMapVerticalHorizontal = cellsMapVerticalHorizontal;
        }

        Object.keys( table.children ).forEach( childShapeId => {
            delete this.changeModel.shapes[ childShapeId ];
        });
        table.children = {};
        Object.keys( table.containerRegions ).forEach( regId => {
            table.containerRegions[ regId ].shapes = {};
        });

        this.modifyTableFromCellData( table );
    }

    protected modifyTableFromCellData( table: ShapeModel ) {
        if ( !this.data.cells ) {
            return;
        }
        const cells = Object.values( table.data )
            .filter(( data:  any ) =>
                data.type === DataType.CHILD_SHAPE )
            .map(( data:  any )  => data.value );
        cells.forEach(( cell: any ) => {
            const styles = this.data.cells[ cell.rowId + '-' + cell.columnId ];
            if ( styles ) {
                const defaultCell = this.data.defaultCell;
                const style = {
                    fillColor: styles.fillColor || defaultCell.fillColor,
                    lineThickness: styles.lineThickness || defaultCell.lineThickness,
                    lineColor: styles.lineColor || defaultCell.lineColor,
                    lineStyle:  styles.lineStyle || defaultCell.lineStyle,
                };
                if ( table.styleDefinitions[ cell.id ]?.style ) {
                    Object.assign( table.styleDefinitions[ cell.id ].style, style );

                } else {
                    table.styleDefinitions[ cell.id ] = { style } as any;
                }
                if ( styles.textColor || styles.textSize || styles.text !== undefined ) {
                    const text = table.texts[ cell.id ];
                    if ( text ) {
                        text.content = ( text.content || []).map( run => {
                            run.color = styles.textColor || run.color;
                            run.size = styles.textSize || run.size;
                            return run;
                        });
                        if ( styles.text !== undefined ) {
                            const textContent = { ...text.content[0], text: styles.text };

                            if ( styles.textAlignX !== undefined ) {
                                textContent.align = styles.textAlignX === 0 ? 'center' : styles.textAlignX === 1 ? 'right' : 'left';
                                text._alignX = styles.textAlignX;
                            }
                            if ( styles.textAlignY !== undefined ) {
                                text.alignY = styles.textAlignY;
                            }

                            text.content = [ textContent ];
                        }
                    }
                }

                const _text = table.texts[ cell.id ];
                if ( _text ) {
                    const b = this.getTextBounds( _text.content, cell.width - TEXT_PADDING_HORIZONTAL * 2 );
                    _text.width = b.width;
                    _text.height = b.height;
                }
            }
        });
    }

    protected mergeCells( table: ShapeModel, _cells ) {
        if ( this.data.mergedCells ) {
            this.data.mergedCells.forEach( group => {
                const cells = group
                    .map( cell => _cells.find( c => `${c.rowId}-${c.columnId}` === cell )?.id );
                ( table as any ).mergeCells( table, cells );
            });
        }
    }

    protected getTextBounds( data: Object[] | string, width: number = 10000 ) {
        return Carota.bounds( data, width, DEFUALT_TEXT_STYLES );
    }

}

Object.defineProperty( ModifyTable, 'name', {
    value: 'ModifyTable',
});
