import { EDataModel } from "../model/edata.mdl";

const isValidEmail = ( str: string ) => {
    // Regular expression for validating an email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email string against the regular expression
    return emailRegex.test( str );
};

export const mapUserEntries = ( entries, userData, model: EDataModel ) => ({
    source: {
        id: model.id,
        name: model.name,
    },
    people: entries.map( entry => {

        const userDataKeys = Object.keys( userData );

        let userRecord = {
            id: null,
            email: null,
            fullName: null,
            hasImage: true,
            image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/White_square_with_question_mark.svg/2048px-White_square_with_question_mark.svg.png', // This need to be replaced
        };

        // If the entry is an Email, Check it with userData emails,
        // Otherwise, Check with names
        if ( isValidEmail( entry )) {
            // Since the emails are case insensitive, We have to check according to that.
            const lowerCaseEmail = entry.trim().toLowerCase();
            for ( let i = 0; i < userDataKeys.length; i++ ) {
                const lowerCaseKey = userDataKeys[i].toLowerCase();
                if ( lowerCaseEmail === lowerCaseKey ) {
                    userRecord = { ...userData[userDataKeys[i]] };
                    break;
                }
            }

            userRecord.email = lowerCaseEmail;
            userRecord.fullName = lowerCaseEmail;

        } else {
            const lowerCaseName = entry.trim().toLowerCase();
            for ( let i = 0; i < userDataKeys.length; i++ ) {

                const userDataObj = userData[userDataKeys[i]];
                const lowerCaseFullname = userDataObj.fullName.toLowerCase();
                console.log(userDataObj);
                console.log(lowerCaseName, lowerCaseFullname);

                if ( lowerCaseName === lowerCaseFullname ) {
                    userRecord = { ...userData[userDataKeys[i]] };
                    break;
                }
            }

            userRecord.fullName = entry;
        }

        if ( !userRecord.id ) {
            userRecord.id = btoa( userRecord.email || userRecord.fullName );
        }

        return userRecord;
    }),
});
