import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { DEFUALT_TEXT_STYLES, ITextContent, TEXT_PADDING_HORIZONTAL } from 'flux-definition/src';
import { TextFormatter } from 'flux-diagram-composer';
import { Rectangle } from '@creately/createjs-module';
import * as Carota from '@creately/carota';
import { ShapeModel } from 'apps/nucleus/src/base/shape/model/shape.mdl';
import { TiptapDocumentsManagerShapeText } from '../../../base/ui/text-editor/tiptap-documents-manager-shape-text.cmp';
import { tap } from 'rxjs/operators';
import { CsvUtil } from 'apps/nucleus/src/base/diagram/export/csv-util';

const DATA_ITEMS_LABELS_TEXT_ID = 'data_items_labels';

const DATA_ITEMS_VALUES_TEXT_ID = 'data_items_values';

/**
 * SyncShapeDataItems
 * This commad runs after the ChangeShapeDataItems command
 * It cleans up the data_items text field, removing data items that have been deleted.
 * It also removes items with modified labels.
 */
@Injectable()
@Command()
export class SyncShapeDataItems extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
    public data: {
        shapeIds: any,
    };

    protected formatter: TextFormatter;

    constructor( protected ds: DiagramChangeService ) {
        super( ds ) /* istanbul ignore next */;
        this.formatter = new TextFormatter();
    }

    public prepareData() {
        for ( const shapeId in this.data ) {
            const dataItems = this.data[ shapeId ];
            const shape = this.changeModel.shapes[ shapeId ] as ShapeModel;

            for ( const path in dataItems ) {
                const dataItem = dataItems[ path ];
                if ( dataItem && shape.renderedDataFields?.includes( path )) {
                    this.updateText( dataItem, shape );
                } else if ( dataItem === undefined ) {
                    this.removeText( path, shape );
                }
            }
            this.updateShapeScale( shape );
        }
    }

    protected updateText ( dataItem, shape: ShapeModel ) {
        const text = shape.texts[ DATA_ITEMS_LABELS_TEXT_ID ] as any;
        if ( text ) {
            if ( text.content.length > 0 && shape?.renderedDataFields.includes( dataItem.id )) {
                const label = text.content.find( segment => segment.data_item === dataItem.id );
                if ( label.text.startsWith( '\n' )) {
                    label.text = `\n${dataItem.label}`;
                } else {
                    label.text = `${dataItem.label}`;
                }
                const valueTxt = shape.texts[ DATA_ITEMS_VALUES_TEXT_ID ] as any;
                const value = valueTxt.content.find( segment => segment.data_item === dataItem.id );
                if ( value.text.startsWith( '\n' )) {
                    value.text = `\n${this.parseDataItem( dataItem )}`;
                } else {
                    value.text = `${this.parseDataItem( dataItem )}`;
                }
                const lbounds = this.calculateBounds( text.content );
                const vbounds = this.calculateBounds( valueTxt.content );
                text.width = lbounds.width;
                text.height = lbounds.height;
                valueTxt.width = vbounds.width;
                valueTxt.height = vbounds.height;
            }
        }
    }

    protected parseDataItem( dataItem ): string {
        const formatter = CsvUtil.getFormatter( dataItem.type );
        return formatter( dataItem );
    }

    protected removeText ( dataItem: string, shape: any ) {
        const labelText = shape.texts[ DATA_ITEMS_LABELS_TEXT_ID ];
        const valueText = shape.texts[ DATA_ITEMS_VALUES_TEXT_ID ];
        if ( !labelText || !valueText ) {
            return;
        }
        const lcontent = labelText.content.filter(( text: any ) => text.data_item !== dataItem );
        const vcontent = valueText.content.filter(( text: any ) => text.data_item !== dataItem );
        const handlebars = JSON.parse( labelText.handlebars );
        const lbounds = this.calculateBounds( lcontent );
        const vbounds = this.calculateBounds( vcontent );
        if ( lcontent.length > 0 ) {
            labelText.handlebars = JSON.stringify( handlebars );
            labelText.content = lcontent;
            labelText.width = lbounds.width;
            labelText.height = lbounds.height;
        } else {
            delete shape.texts[ DATA_ITEMS_LABELS_TEXT_ID ];
        }
        if ( vcontent.length > 0 ) {
            valueText.handlebars = JSON.stringify( handlebars );
            valueText.content = vcontent;
            valueText.width = vbounds.width;
            valueText.height = vbounds.height;
        } else {
            delete shape.texts[ DATA_ITEMS_VALUES_TEXT_ID ];
            this.repositionPrimaryText( shape );
        }
    }

    protected calculateBounds( content: ITextContent[]): Rectangle {
        return Carota.bounds( content, 999999, DEFUALT_TEXT_STYLES );
    }

    /**
     * Position primary text back to center of shape when all dataItems are removed
     * @param shape
     */
    protected repositionPrimaryText( shape: ShapeModel ) {
        if ( Object.keys( shape.texts ).length === 1 ) {
            const primary: any = shape.primaryTextModel;
            if ( primary.rendering === 'tiptapCanvas' || primary.rendering === 'dom' ) {
                TiptapDocumentsManagerShapeText
                .applyTextStyles( this.changeModel.id, shape.id, primary.id, { align: 'center' }).pipe(
                    tap( formatted => {
                        if ( formatted ) {
                            primary.html = formatted;
                        }
                    }),
                ).subscribe();
            } else {
                TiptapDocumentsManagerShapeText
                .applyTextStyles( this.changeModel.id, shape.id, primary.id, { align: 'center' }).subscribe();
                const primaryContent = this.formatter.applyRaw(
                    primary.content, { indexStart: 0, indexEnd: undefined, styles: { align: 'center' }}, false );
                primary.content = primaryContent;
                primary.value = Carota.html.html( primaryContent );
            }
            primary.xType = 'relative';
            primary.yType = 'relative';
            primary.positionString = 'in-center-center';
            primary.x = 0.5;
            primary.y = 0.5;
            primary.alignX = 0;
            primary.alignY = 0;
            primary._alignX = 0;
        }
    }

    /**
     * Update the shape scale to match the newly changed values
     */
    protected updateShapeScale( shape: ShapeModel ) {
        const primary = shape.primaryTextModel;
        const dataTextHeight = Math.max( shape.texts[ DATA_ITEMS_LABELS_TEXT_ID ]?.height,
            shape.texts[ DATA_ITEMS_VALUES_TEXT_ID ]?.height );
        const dataWidth = shape.texts[ DATA_ITEMS_LABELS_TEXT_ID ]?.width +
            shape.texts[ DATA_ITEMS_VALUES_TEXT_ID ]?.width + 4 * TEXT_PADDING_HORIZONTAL;
        if ( dataWidth ) {
            shape.scaleX = Math.max( shape.scaleX,
                Math.max( dataWidth, primary.width + 2 * TEXT_PADDING_HORIZONTAL ) / shape.defaultBounds.width );
            shape.minBounds = { ...shape.minBounds, width: dataWidth };
        }
        if ( dataTextHeight ) {
            shape.scaleY = ( dataTextHeight + primary.height + 25 ) / shape.defaultBounds.height;
            const transformSettings = { ...shape.transformSettings, fixAspectRatio: false, rotate: false };
            shape.transformSettings = transformSettings;
        } else {
            if ( Object.keys( shape.texts ).length === 1 ) {
                if ( shape.userSetHeight !== undefined ) {
                    shape.scaleY = shape.userSetHeight / shape.defaultBounds.height;
                } else {
                    shape.scaleY = Math.max((( primary.height + 10 ) / shape.defaultBounds.height ), 1 );
                }
            }
            delete shape.minBounds;
        }
    }
}

Object.defineProperty( SyncShapeDataItems, 'name', {
    value: 'SyncShapeDataItems',
});
